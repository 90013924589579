:root {
  --thumb-width: 51px;
  /* Default value, adjust as needed */
  --thumb-position: 0;
  /* Default position at 0% */
}

.inner {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 1rem;
  background: white;
  z-index: 1000000000;
}

.wrapper {
  position: absolute;
  display: flex;
  background: white;
}

section {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}


@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}




/* Toastify custom css */
.Toastify__toast-icon {
  align-self: flex-start;
}

.Toastify__toast {
  font-family: "Satoshi-Variable", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  position: absolute;
}

/* Custom styles for success toast */
.custom-success-toast {
  background-color: #CDECDA;
  height: 85px;
  width: 433px;
  right: 125px;
  top: 62px;
}

/* Custom styles for warning toast */
.custom-warning-toast {
  background-color: #F9ECD3;
}

/* Custom styles for error toast */
.custom-error-toast {
  background-color: #FFD4C9;
}

/* Custom styles for info toast */
.custom-info-toast {
  background-color: rgba(193, 193, 193, 0.22);
}

.confirm-popup-toast {
  width: 428px;
  min-height: 54px;
  padding: initial;
  margin: 0;
  bottom: 138px;
  right: 46px;
  box-shadow: none;
  border-radius: 8px;
}

.confirm-popup-toast-position {
  width: 428px;
  min-height: 54px;
  padding: initial;
  margin: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, -400%);
  box-shadow: none;
  border-radius: 8px;
}

.confirm-popup-toast .Toastify__toast-body {
  padding: 0px 0px 0px 12px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.confirm-popup-toast-position .Toastify__toast-body {
  padding: 0px 0px 0px 12px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}


/*  ================================ Header CSS =================================== */
.app-logo {
  height: 40px;
  width: 40px;
}

.container-fluid {
  --bs-gutter-x: 0px;
}


.navbar-nav {
  font-size: 0.875rem;
}

.nav-link {
  color: black;
}

.inactive {
  color: black;
  text-decoration: none !important;
  font-weight: 500;
}

.active {
  color: #383fec;
  text-decoration: none !important;
  font-weight: 500;
}

.header {
  grid-column: 1 / span 16;
  height: 60px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

}

.symbol-name {
  font-size: 0.625rem;
  color: #4a4a4a;

}

.ch-chp {
  font-size: 0.75rem;
}

.nifty,
.banknifty,
.indiavix {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.symbol-containers {
  width: 175px;
}

.broker-id {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.broker-name {
  color: #000;
  font-size: 10px;
  font-weight: 500;
  line-height: 0px;
}

.user-profile-dropdown-menu {
  border-radius: 4px;
  border: 1px solid #989898;
  --bs-dropdown-min-width: 91px;
}

.user-profile-dropdown-menu .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.user-profile-dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -60px;
  margin-top: var(--bs-dropdown-spacer);
}

.drp {
  left: -20px !important;
}

/*  ================================ Orders Page CSS =================================== */
.orders-page {
  grid-column: 1 / span 16;
  margin-top: 29px;
}

.orders-page .btn-group-1 {
  gap: 0.75rem;
}

.orders-page .btn-1 {
  background-color: #f4f4f4;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  height: 30px;
}

.orders-page .btn-1.active {
  background-color: black;
  color: #383FEC;
  background: #F3F4FF;
}

.orders-page .all {
  width: 30px;
}

.orders-page .executed {
  width: 85px;
}

.orders-page .pending,
.rejected {
  width: 75px;
}

.orders-page .cancel {
  width: 118px;
  color: white;
  background: #C1C1C1;
}

.orders-page .product {
  width: 73px;
  height: 24px;
  background: #FFEDCB;
  color: #E2A838;
}

.orders-page th,
tr {
  color: #383FEC;
  font-size: 0.875rem;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.orders-page td {
  font-size: 0.875rem;

  font-weight: 500;
}

.orders-page tr {
  color: #000;
}

.orders-page table thead th {
  background: #FAFAFF;
}

.orders-page .table {
  border-collapse: separate;
  border-radius: 4px;
  border: 1px solid #989898;
  border-spacing: 0px 10px;
}


.info-icon {
  height: 18px;
  width: 18px;
  display: inline-block;
  text-align: center;
  border: 1px solid black;
}

/*  ============================== Option Chain Page CSS ================================= */

.options-menu {
  display: grid !important;
  grid-auto-flow: column;
}

.option-chain-table-container {
  grid-column: span 16;
  gap: 16px;
}


.symbol-selection {
  border: 1px solid #989898;
  border-radius: 4px;
  padding: 16px 14px;
  min-width: 382px;
  width: 510px;
  height: 126px;
}

.symbol-info .symbol-name {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}


.header-box {
  padding: 14px;
}

.market-info {
  color: #555;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.symbol-info .lp {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.symbol-info .lp-decimal {
  font-weight: 400 !important;
}

.symbol-info .ch-chp {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 4px;
}

.symbol-info .search {
  border-radius: 2px;
  border: 1px solid #C1C1C1;
  height: 32px;
  width: 32px;
}


.custom-range-container {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.custom-range {
  width: 232px;
  height: 8px;
  background: #ebebeb;
  appearance: none;
  outline: none;
}

.custom-range::-webkit-slider-thumb {
  appearance: none;
  width: var(--thumb-width);
  height: 8px;
  background: var(--slider-color, #5CA81D);
  margin-left: var(--thumb-position);
}

.slider {
  position: absolute;
  top: 85%;
  transform: translateY(-50%);
  width: 11px;
  height: 8px;
  flex-shrink: 0;
}

.range-label {
  color: #555;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.symbol-most-traded .most-traded-title {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-right: 12px;
}

.symbol-most-traded .most-traded {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  padding: 5px 10px;
  background: #f4f4f4;
}

.symbol-most-traded-active {
  background: #F3F4FF !important;
  color: #383FEC !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}


.symbol-date .date {
  width: 101px;
  height: 30px;
  border-radius: 4px;
  background: #F4F4F4;
  font-size: 14px;
  font-weight: 500;
}

.symbol-date .date.active {
  background: #E3E4FF;
  color: #383FEC;
}

.expiry-date-title {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

/* Seach Modal CSS */
.search-modal.modal {
  position: absolute;
  /* left: 100px;
  bottom: 200px; */
}

.search-modal .modal-header {
  border-bottom: none;
}

.search-modal .modal-dialog {
  width: 319px;
  height: 330px;
  position: absolute;
  left: 9rem;
  min-height: auto;
  margin-top: 16rem;
}


.search-modal .modal-content {
  border-radius: 4px !important;
  /* margin-top: 115rem; */
  max-height: 44rem;

}

.search-modal .modal-body {
  max-height: 70rem;
  overflow-y: hidden;
}


.search-modal input {
  width: 243px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #555;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.search-modal .pop-searches-title {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.search-modal .pop-searches-symbol {
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}

.search-modal .index {
  width: 44.788px;
  height: 20px;
  background: #EFEFEF;
  color: #1F1E1E;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.search-modal li {
  margin-bottom: 1rem;
}


/* =============================  data ======================= */
.data-group {
  /* width: 100%; */
  grid-column: span 10;
  width: 870px;
}

.data {
  height: 64px;
  border: 1px solid #989898;
  border-radius: 4px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  /* width: 865.496px; */
}

.empty-basket-text {
  color: #555;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.data-group-title {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.data-group-value {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.basket-icon {
  padding: 0px 20px;
  background: linear-gradient(135deg, #757AFA 1.92%, #383FEC 100%);
}

.clear-basket-btn {
  color: #383FEC;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #383FEC;
  background: #FFF;
}

.basket-data-values {
  padding: 12px 16px 12px 0;
}

.date-dropdown-btn {
  padding: 5px 10px;
  color: #383FEC;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #989898;
  background: #FFF;
}

.oc-date-dropdown-menu {
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #989898;
  background: #FFF;
}

/* Add this style to your CSS */
.date-dropdown-btn::after {
  display: none;
}

.expiry-days {
  font-size: 12px;
}

.settings,
.open-basket {
  padding: 6px 12px;
  border-radius: 4px;

}

.open-basket-disabled {
  pointer-events: none;
  opacity: 0.2;
  cursor: not-allowed;
  background: #DADADA;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  background: #DADADA;
  color: white;
}

.settings {
  border: 1px solid #000;
  background: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.open-basket {
  background: #383FEC;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border: none;
}

.basket-badge {
  border-radius: 50%;
  padding: 4px 10px;
  background: #FFE608;
  font-size: 12px;
  font-weight: 500;
}

/* Option Chain Table CSS */

/* Hide the scrollbar for WebKit-based browsers */
.option-chain-table-container {
  overflow: hidden;
  margin-top: 16px;
  /* Hide the native scrollbar */
}

.option-chain-table-container table {
  margin-right: 0;
  /* Prevent extra space caused by hidden scrollbar */
}

.option-chain-table-container::-webkit-scrollbar {
  width: 0;
}

.option-chain-table-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.option-chain-table-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.option-chain-table {
  border: 1px solid #DADADA;
}

.option-chain-table .calls-puts {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  width: 100%;

}

.option-chain-table .calls {
  color: #E2A838;

}

.option-chain-table .puts {
  color: #757AFA;
}

.option-chain-table .table-header-columns {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.table-responsive.tabla {
  overflow-y: auto;
}

/* Hide the vertical scrollbar */
.table-responsive.tabla::-webkit-scrollbar {
  width: 0em;
}

.table-responsive.tabla::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}


.option-chain-table .table-header-columnss {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.strike-header {
  margin-top: none !important;
}

.option-chain-table td {
  font-size: 14px;
  vertical-align: middle;
  font-weight: 500;
}

.option-chain-table .strike {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  border: 1px solid #DADADA;
  background: #F5F5F5;
}

/* Styles for yellow rows (calls side) */
.option-chain-table tbody tr.yellow {
  background-color: #FFF9E5;
}

/* Styles for blue rows (puts side) */
.option-chain-table tbody tr.blue {
  background-color: #F4F5FF;
}

.max-pain-row {
  padding: 0.07rem !important;
}

.max-pain-label {
  width: 54px;
  height: 14px;
  color: #383FEC;
  font-size: 10px;
  font-weight: 500;
  line-height: 0px;
  background: #AAADF7;
}

/* Setting Modal CSS */
.settings-modal .modal-header {
  border-bottom: none;
}


.settings-modal .modal-content {
  border-radius: 4px !important;
  width: 546px;
  height: 339px;
}

.settings-modal .modal-title {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.settings-modal .modal-tag-titles {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.settings-modal li {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.settings-modal .ltp-range {
  width: 115px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
}


.settings-modal .default-dots {
  width: 65px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.settings-modal .dropdown-toggle {
  width: 80px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
}

.settings-modal .modal-footer {
  border-top: none;
}

.settings-modal .reset-btn {
  width: 75px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 0px;
  background: white;
}

.settings-modal .apply-btn {
  width: 80px;
  height: 36px;
  border-radius: 4px;
  background: #383FEC;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 0px;
  border: none;
}


/* ==================== STRATEGY CREATOR PAGE CSS =========== */

.left {
  grid-column: span 4;
  gap: 12px;
}

.right {
  grid-column: span 12;
  gap: 12px;
}

.limit-banner {
  width: 510px;
  height: 66px;
  border-radius: 4px;
  font-weight: 500;
  background: linear-gradient(89deg, #FFB4B4 0.16%, #CA4D4D 68.8%);
}

.limit-risk-text {
  font-size: 16px;
}

.limit-risk-btn {
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #383FEC;
  background: #FFF;
  padding: 6px 12px;
  color: #383FEC;
  border: 1px solid #383FEC;
}

.create-strategy {
  width: 510px;
  border-radius: 4px;
  border: 1px solid #989898;
}

.header-box {
  padding: 14px;
}

.create-strategy .bullish {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.strategy-table>:not(caption)>*>* {
  padding: 0.5rem 0rem;
  border: none;
}

.table-first-container {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 #555555;
  height: 210px;
  width: fit-content;
}

.table-first-container::-webkit-scrollbar {
  width: 6px;
}

.table-first-container::-webkit-scrollbar-track {
  background-color: #555555;
}

.table-first-container::-webkit-scrollbar-thumb {
  background-color: #999999;
}

.create-strategy-btn {
  width: 119px;
  height: 31px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
}

.btn-op {
  color: #383FEC;
  border: 1px solid #383FEC;
  background: #fff;
}

.btn-screener {
  color: #FFF;
  background: linear-gradient(180deg, #FF420E 0%, #FF702E 100%);
  border: none;
}

.btn-date {
  font-size: 12px;
  /*changed to 11 for now*/
  font-weight: 500;
  line-height: 0px;
  width: 84px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #FFF;
}

.strategy-strike {
  color: #000;
  width: 88px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
  background: #fff;
}


.strategy-lots {
  color: #000;
  width: 88px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
  background: #fff;
}

.strategy-lots span:active {
  transform: scale(0.9);
}

.strategy-strike span:active {
  transform: scale(0.9);
}

.price {
  color: #000;
  width: 84px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
}

.price-input {
  width: 60px;
  border: none;
  outline: none;
  text-align: end;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
}

.more {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}



.create-strategy-actions {
  height: 63px;
  border-radius: 0px 0px 4px 4px;
  border-top: 2px solid #EFEFEF;
  background: #FAFAFF;
  padding: 0 14px;
}

/* //////////////////////////////// */
.toggle-container {
  display: flex;
  width: 135px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 0px 3px;
}

.toggle-button {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  outline: none;
  border: none;
  background: #FFF;
  color: #383FEC;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 27px;

}

.toggle-button.active {
  background: #383FEC;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.lotx {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.execute-order-btn {
  border-radius: 4px;
  background: #383FEC;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
}

.creator-bottom {
  padding: 8px 14px;
}

.creator-bottom-title {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.creator-bottom-value {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.confirm-popup-ok {
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 0px 8px 8px 0px;
  background: #70D11F;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.overlay.active {
  opacity: 1;
  pointer-events: auto;
  z-index: 1000;
}

.risk-factor {
  width: 510px;
  height: 280px;
  border-radius: 4px;
  border: 1px solid #989898;
  background: #FFF;
  overflow: hidden;
  padding: 14px;
}

.filter-btns {
  border-radius: 4px;
  background: #F4F4F4;
  border: #F4F4F4;
  height: 30px;
  color: #555;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.filter-btns.active {
  color: #383FEC;
  background: #F3F4FF;
}


.box-container {
  height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 #555555;
}

.box-container::-webkit-scrollbar {
  width: 6px;
}

.box-container::-webkit-scrollbar-thumb {
  background-color: #999999;
}

.box-container::-webkit-scrollbar-track {
  background-color: #555555;
}

.box {
  border-radius: 4px;
  border: 1px solid #EFEFEF;
}

.iron-title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.iron-text {
  color: #555;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.low-risk {
  padding: 0.5px 4px;
  background: #FFEDCB;
  color: #E2A838;
  font-size: 12px;
  font-weight: 500;
}

.high-risk {
  padding: 0.5px 4px;
  background: #FFCDCD;
  color: #DD6565;
  font-size: 12px;
  font-weight: 500;
}

.hedged {
  padding: 0.5px 4px;
  background: #D8FFB8;
  color: #5CA81D;
  font-size: 12px;
  font-weight: 500;
}

.unhedged {
  padding: 0.5px 4px;
  background: #FFCDCD;
  color: #DD6565;
  font-size: 12px;
  font-weight: 500;
}

.disabled-delete {
  pointer-events: none;
  color: #C1C1C1;
}

/* Right side of strategy creator  */
.strategy-menu {
  height: 64px;
  border-radius: 4px;
  border: 1px solid #989898;
}

.strategy-menu-value {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}


.strategy-instruments {
  height: 286px;
  border-radius: 4px;
  border: 1px solid #989898;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;
}

.table-container {
  overflow-x: auto;
  flex: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 #555555;

}

.table-container::-webkit-scrollbar {
  width: 6px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #555555;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #999999;
}

.strategy-tablee {
  width: 100%;
  border-collapse: collapse;
}

.tr-strategy-headerr,
.screener-tbl-header {
  background: #FAFAFF;
  position: sticky;
  top: 0;
  z-index: 1;

}


/* .tr-strategy-headerr{
  padding: 0 14px;
} */


.tr-strategy-headerr th {
  color: #383FEC;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.screener-tbl-header {
  color: #383FEC;
  font-size: 12px;
  font-weight: 400;
  line-height: 0px;
}

.table-cell {
  padding: 4px 28px;
  white-space: nowrap;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.iv {
  color: #000;
  width: 54px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
}


.strategy-graph {
  border-radius: 4px;
  border: 1px solid #989898;
  gap: 8px;
}

.graph-text {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.main-graph {
  width: 795px;
  height: 349px;
  border: 2px solid green;
}

/* Custom styles for the range input */
#custom-range {
  width: 273px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 40px;
  background: #D9DBE9;
}

/* #custom-range::-webkit-slider-thumb {
  width: 16px;
  height: 12px;
  border-radius: 50%;
  background: #383FEC !important;
  cursor: pointer;
  -webkit-appearance: none;
} */

#custom-range::-moz-range-thumb {
  width: 16px;
  height: 12px;
  border-radius: 50%;
  background: #383FEC !important;
  cursor: pointer;
  -webkit-appearance: none;
}

#custom-range::-ms-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #383FEC !important;
  cursor: pointer;
  -webkit-appearance: none;
}



.estimated-total {
  color: #5CA81D;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.graph-footer-1 {
  position: absolute;
  bottom: calc(100% + 4px);
  display: inline-flex;
  padding: 6px 16px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #383FEC;
  color: #fff;
  font-size: 12px;
  text-align: center;
  pointer-events: none;
}

.graph-footer-2 {
  width: 0;
  height: 0;
  border-top: 8px solid #383FEC;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  pointer-events: none;


}




/* Screener Modal CSS */
.screener-modal .modal-content {
  width: 464px;
  height: 359px;
  border-radius: 0px;
  border: none;
  background: #FFF;
}

.screener-modal .modal-header {
  padding: 1rem 1rem 0 1rem;
}

.fixed-section {
  position: sticky;
  top: 0;
  z-index: 100;
  background: #fff;
}

.scrollable-section {
  flex: 1;
  overflow: auto;
  padding: 10px;
}

.screener-modal thead {
  border: 1px solid #989898;
}

.screener-modal .modal-header .screener-name {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.screener-moadal-table>:not(caption)>*>* {
  padding: 0.5rem 0rem;
  border: none;
}

.screener-moadal-table {
  border-collapse: collapse;
  width: 100%;
}

.screener-modal-container {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 #555555;
  height: 150px;
}

.screener-tbl-header th {
  color: #383FEC;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.expiry-dropdown-screener {
  width: 108px !important;
  color: #000 !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
}

.greek-dropdown-screener {
  width: 71px !important;
  color: #000 !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
}

.screnner-modal-dropdown .btn {
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #FFF;

}

.screening-condition .dropdown-toggle::after {
  margin-left: 3.9em;
}

.screening-condition .dropdown-toggle {
  position: relative;
}

.screening-condition .dropdown-toggle::after {
  content: "";
  position: absolute;
  right: 10px;
}

.screnner-modal-dropdown .dropdown-menu {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  --bs-dropdown-min-width: 4rem;
  width: -webkit-fill-available;
}

.screnner-modal-dropdown li {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-dropdownn button {
  width: 122px;
  height: 36px;

}

.scr-con {
  width: 39px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
}

/* Disable number input spinners */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.row-close {
  width: 11px;
  height: 11px;
}

.modal-footer .d-flex button {
  height: 31px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.modal-footer .cancel {
  display: inline-flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border: 1px solid #C1C1C1;
  background: #FFF;
  color: #000;

}

.modal-footer .saveandscreen {
  display: flex;
  width: 112px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #383FEC;
  color: #FFF;
  border: 1px solid #383FEC;


}

.screener-modal .modal-footer,
.modal-header {
  border: none;
}

/* ScreenerModal.css */

.disabled-row {
  /* opacity: 0.2; */
}

.disabled-row .bs-value {
  background-color: #c1c1c1;
  color: white;
}

.disabled-row .bs-value {
  background-color: #c1c1c1 !important;
  color: white !important;
}

.disabled .ce {
  border: 1px solid #c1c1c1 !important;
  color: #c1c1c1 !important;
}

.disabled-row .strategy-lots span {
  color: #c1c1c1;
}

.disabled-row .dropdown-toggle {
  border-color: #c1c1c1;
  color: #c1c1c1;
}

.disabled-row input[type="checkbox"] {
  cursor: not-allowed;
}

.disabled-row .scr-con {
  border-color: #c1c1c1;
  color: #c1c1c1;
}

.disabled-row span[role="button"] svg {
  fill: #c1c1c1;
}

.disabled {
  pointer-events: none;

}

/* ============================ Positions Page CSS =============== */

.pos-left {
  width: 371px;
  border: 1px solid #989898;
  border-radius: 4px;
  height: 189px;
}

.pos-right {
  grid-column: span 11;
  gap: 16px;
  height: 864px;
}

.symbol-name-pos {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.text-green {
  color: #5CA81D;
}

.text-red {
  color: #DD6565;
}

.watchlist {
  height: 864px;
  border: 1px solid #989898;
  border-radius: 4px;
}

.pos-menu {
  height: 66px;
  border-radius: 4px;
  border: 1px solid #989898;
}

.pos-strategy-menu {
  height: 189px;
}

.pos-strategy-menu-value {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.position-analytic {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.exit {
  margin-left: auto;
  border: none;
  border-radius: 4px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.exit.disabled {
  background: #C1C1C1;
  pointer-events: none;
}

.exit.enabled {
  background: #383FEC;
}



.group-container,
.pos-table-style {
  border: 1px solid #989898;
  border-radius: 4px;
}

.position-table-header {
  background: #FAFAFF;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 36px;
}

.position-table-header th {
  color: #383FEC;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

/* Common styles for both product types */
.product-type {
  width: 73px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.product-type.Normal {
  color: #383FEC;
  background: #DBDCFF;

}

.product-type.Intraday {
  color: #E2A838;
  background: #FFEDCB;
}

/* Common styles for both "BUY" and "SELL" */
.buy-sell {
  text-align: center;
}

.pos-buy {
  color: #5CA81D !important;
}

.pos-sell {
  color: #DD4B27 !important;
}

.position-table tr {
  height: 36px;
}

.position-table .table-data {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.group-menu {
  margin-bottom: 0px;
}

.analyse-op {
  height: 32px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #FFF;
  color: #989898;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
}

.pos-exit-analyse {
  width: 56px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #989898;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.pos-exit-analyse,
.analyse-op.enabled {
  background: #383FEC;
  color: white;

}

.analyse-op.enabled {
  background: white;
  color: #383FEC;
  border-color: #383FEC;

}

.pos-exit-analyse.disabled {
  background: #989898;
}

.strategy-name {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;

}

.pos-custom-range {
  width: 244px;
  height: 8px;
  background: #DD6565;
  appearance: none;
  outline: none;
}

.pos-slider {
  width: 70px;
  background-color: #5CA81D;
  position: absolute;
  top: 92%;
  transform: translateY(-50%);
  height: 8px;
  flex-shrink: 0;
}

.pos-slider::before,
.pos-slider::after {
  content: "";
  position: absolute;
  /* height of black edges */
  top: -4px;
  bottom: -4px;
  width: 4px;
  background-color: black;
}

.pos-slider::before {
  left: 0px;
}

.pos-slider::after {
  right: 0px;
}

/* Watchlist */

.watchlist-title {
  color: #555;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.watchlist-search {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #555;
}

.list-elements {
  border-bottom: 1px solid #989898;
}

.list-symbol-title,
.list-symbol-title-value {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.symbol-list-index {
  height: 20px;
  background: #EFEFEF;
  color: #1F1E1E;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}



/* //////////////////////////////////// */
.list-hover-value {
  display: block;
}

.list-hover-buttons {
  display: none;
}

.list-elements:hover .list-hover-value {
  display: none;
}

.list-elements:hover .list-hover-buttons {
  display: block;
}

.hamburger-btn {
  border: 1px solid #989898;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.buy-btn {
  width: 41px;
  height: 24px;
  border-radius: 4px;
  background: #70D11F;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
  border: none;
}

.sell-btn {
  width: 41px;
  height: 24px;
  border-radius: 4px;
  background: #FF3908;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
  border: none;
}

.watchlist-tables,
.watchlist-tables tr,
.watchlist-tables th {
  color: #555;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.watchlist-tables>div:first-child table tbody tr td:last-child {
  border-right: 1px solid #e2e2e2;
}

/* CSS For Option Chain Modal */
.option-chain-modal-content {
  border-radius: 0;
  overflow-y: scroll;
  height: 815px;
}

.option-chain-table {
  position: relative;
}

.option-chain-table thead {
  position: sticky;
  top: -1;
  background-color: #fff;
  z-index: 998;
}

.analyse-chain-modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px !important;
}

.analyse-chain-modal-content {
  border-radius: 0;
  width: 915px;
  height: 800px;
}

.analyse-modal-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.highcharts-credits {
  display: none !important;
}


/* //strategy creator dropdown-menu */
.strategy-table-row .dropdown-menu {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  --bs-dropdown-min-width: 5rem;
}


/* .strategy-table-row{
  padding: 0 14px;
} */


.dropdown-item-hover:hover {
  background-color: #f1f1f1;
  color: #000;
}

.hover-lots-value {
  width: 42px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #FFF;
}

/* BASKET MODAL CSS */

.basket-modal .modal-content {
  border-radius: 0px;
  width: auto;
  min-height: 420px;
}

.basket-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 876px;

}

.basket-builder-btn {
  width: 139px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
  border-radius: 4px;
  border: 1px solid #989898;
  background: #FFF;
}

.basket-order-btn {
  width: 132px;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
  background: #383FEC;
  border: none;
}

.add-target {
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: #383FEC;
  font-size: 12px;
  font-weight: 500;
}

.basket-table-cell {
  padding: 4px;
  white-space: nowrap;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}


/* Buy sell modal css */
.buy-sell-modal .modal-content {
  border-radius: 4px;
  width: 676px;
  height: 346px;


}

.buy-sell-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 676px;

}

.buy-sell-modal .modal-title {
  color: #555;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.buy-sell-impact-btn {
  width: 104px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: white;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
}

.buy-sell-qty-btn {
  width: 121px;
  height: 40px;
  border-radius: 4px;
  background: #FF3908;
  border: #FF3908;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
}

.toggle-containerr {
  display: flex;
  width: 128px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #989898;
  background: #FFF;
  overflow: hidden;
  align-items: center;
  padding: 0px 3px;
}

.toggle-buttonn {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 28px;
}

.toggle-buttonn.buy {
  background: white;
  color: black;
}

.toggle-buttonn.sell {
  background: white;
  color: black;
}

.toggle-buttonn.buy.active {
  background: #70D11F;
  color: white;
}

.toggle-buttonn.sell.active {
  background: #FF3908;
  color: white;
}

.sell-bg {
  background: #FF3908;
}

.buy-bg {
  background: #70D11F;
}

.buy-sell-modal .btn-1 {
  background-color: #f4f4f4;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  height: 30px;
}

.buy-sell-modal .btn-1.active {
  background-color: black;
  color: #383FEC;
  background: #F3F4FF;
}

.marketorder,
.triggerorder,
.coverorder {
  width: 97px;
}

.limitorder {
  width: 89px;
}

.bracketorder {
  width: 106px;
}

.input-container {
  position: relative;
  width: 117px;
  height: 48px;
  background: #FFF;
}

.input-label {
  position: absolute;
  top: -2px;
  left: 10px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.number-input {
  width: 100%;
  height: 100%;
  outline: none;
  border: 1px solid #C1C1C1;
  border-radius: 4px;
}

.number-input-disabled {
  color: #C1C1C1;
}

.row {
  --bs-gutter-x: "0rem";
}

.tag {
  color: white;
  padding: 3px 6px;
}

.buy-tag {
  background-color: #70D11F;
}

.sell-tag {
  background-color: #FF3908;
}


/* =========== Mobile CSS ============= */
.mobile-header {
  display: none;
}


.desktop-header {
  display: block;
}

.mobile-screen {
  display: none !important;
}

.flex-display {
  display: flex !important;
}

.desktop-screen {
  display: contents !important;
}

.desktop-screenn {
  gap: inherit;
  display: grid;
  grid-auto-flow: column;
}

.symbol-containers-mobile {
  width: max-content;
}

.footer {
  /* max-width: 420px; */
  height: 56px;

}

.footer-links-container {
  padding: 0px 12px;
}

.footer-link-names {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.active .footer-link-names {
  color: #383fec;
}

.active path {
  stroke: #383fec;
}

/* Container for the outer scrolling container with fixed width */
.scroll-container-outer {
  width: 420px;
  overflow: hidden;
}

.scroll-container {
  display: flex;
  gap: 24px;
  white-space: nowrap;
  animation: scroll 5s linear infinite backwards;
}

.scroll-container li {
  flex-shrink: 0;
}

.scroll-container::-webkit-scrollbar,
.scroll-container-outer::-webkit-scrollbar {
  display: none;
}


.table-trading-symbol {
  color: #000;
  font-size: 12px;
  font-weight: 500;
}


.bullish-table-menu {
  display: inline-flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #DADADA;
  background: #FFF;
}


.strategy-table-dropdown .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.pos-table-more {
  padding: 8px;
  border: 1px solid #DADADA;
}

.shadow-container {
  position: fixed;
  left: 0;
  bottom: 60px;
  width: 100%;
  z-index: 1;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
}

.pos-check-buttons {
  margin-top: 10px;
}

.pos-button {
  height: 30px;
  border-radius: 4px;
  border: 1px solid #383FEC;
  background: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;

}

.one-click {
  color: #383FEC;
  width: 164px;
}

.exit-pos {
  width: 154px;
  color: #fff;
  background: #383FEC;
}

.mobile-strategy-name {
  background-color: #f8d7c7;
}

.mobile-order-status {
  color: #5CA81D;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

/* BasketMobile.css */
/* CSS for BasketMobile component */
.basket-mobile-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.basket-mobile {
  z-index: 9999;
  height: 711px;
  display: grid;
  column-span: span 4;
  border-radius: 8px 8px 0px 0px;
  background: #FFF;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFF;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
}

.analyse-mobile-modal {
  z-index: 9999;
  height: 633px;
  display: grid;
  column-span: span 4;
  border-radius: 8px 8px 0px 0px;
  background: #FFF;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFF;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
}

.screener-mobile-modal {
  z-index: 9999;
  height: 359px;
  display: grid;
  column-span: span 4;
  border-radius: 8px 8px 0px 0px;
  background: #FFF;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFF;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
}

.dropdown-menu {
  --bs-dropdown-min-width: max-content;
}

.strategy-done {
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #383FEC;
  color: #fff;
  border: none;
}

.expanded-row-name {
  color: #000;
  font-size: 12px;
  font-weight: 500;
}

.expanded-row {
  background: #FBFBFB;

}

/* Media query for screens with a width of 412px or less */
@media (max-width: 420px) {
  .mobile-header {
    display: block;
  }


  .desktop-header {
    display: none;
  }

  .mobile-screen {
    display: block !important;
  }

  .desktop-screen {
    display: none !important;
  }

  .desktop-screenn {
    display: none !important;
  }

  .header {
    margin-top: 14px;
    background: white;
    z-index: 999;
  }

  .symbol-name {
    color: #393939;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;

  }

  .symbol-selection {
    min-width: 0;
    height: auto;
    width: auto;
  }

  .sym-div {
    flex-direction: column;
    align-items: start !important;
    gap: 4px;
  }

  .custom-range-container {
    width: 100%;
  }

  .custom-range {
    width: 100%;
  }

  .option-chain-table-container {
    grid-column: span 4;
  }

  .option-chain-table tr {
    height: 40px;
  }

  /* Strategy creator page */
  .creator-bottom {
    height: 41px;
  }

  .creator-bottom .creator-bottom-title {
    font-size: 12px;
  }

  .creator-bottom-value {
    font-size: 14px;
  }

  .strategy-menu {
    height: 98px;
  }

  .pos-menu-container {
    height: auto;
    padding: 12px 16px;
  }


  .strategy-menu-value {
    font-size: 14px;
  }

  .table-container {
    overflow-x: hidden;
  }

  .create-strategy {
    width: auto;
  }

  .highcharts-background {
    y: -16px;
  }

  .strategy-instruments {
    height: auto;
    margin-bottom: 20px;
  }

  .table-cell {
    padding: 0px;
  }

  .watchlist {
    height: 70vh;
  }

  .table-first-container {
    height: auto;
    width: -webkit-fill-available;
  }

  .confirm-popup-toast {
    width: 350px;
    left: 30px;
  }

  .confirm-popup-toast .Toastify__toast-body {
    padding: 0px 0px 0px 12px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }

  .custom-success-toast {
    background-color: #CDECDA;
    width: 336px;
    right: 44px;
    top: 100px;
  }

  .base-toast p {
    font-size: 12px !important;
  }

  .symbol-info .symbol-name {
    font-size: 18px;
  }

  .symbol-info .lp {
    font-size: 20px;
  }

  .symbol-info .ch-chp {
    font-size: 16px;
  }

  .symbol-most-traded .most-traded {
    font-size: 12px;
  }

  .symbol-most-traded .most-traded-title {
    font-size: 12px;
  }

  .symbol-name {
    margin-bottom: -12px;
  }

  .expiry-date-title {
    font-size: 12px;
  }

  .symbol-date .date {
    font-size: 12px;
    width: 88px;
  }

  .option-chain-table td {
    font-size: 12px;
  }

  .option-chain-table .table-header-columns {
    font-size: 12px;
  }

  td.strike {
    margin: 0;
    padding: 9.85px;
  }

  .search-modal .modal-content {
    border-radius: 8px !important;
    /* margin-top: 115rem; */
    max-height: 125rem
  }

  .search-modal .modal-dialog {
    width: 336px;
    height: 267px;
    position: absolute;
    left: auto;
    min-height: 33rem;
    margin-left: 36px;
    margin-top: 5rem;
  }

  .search-modal .modal-body {
    max-height: 30rem;
    overflow-y: scroll;
  }

  .creator-bottom-title {
    font-size: 12px;
  }

  .basket-order-btn {
    height: 52px;
    width: -webkit-fill-available;
    border-radius: 4px;
  }

  .screener-modal .modal-content {
    width: 360px;
  }

  .screnner-modal-dropdown .btn {
    height: 24px;
  }

  .ce {
    width: 24px;
    height: 24px;
  }

  .strategy-lots {
    width: 71px;
  }

  .screener-modal .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
  }

  .create-strategy-btn {
    border: none;
    width: auto;
  }

  .strategy-graph {
    height: 419px;
  }

  .price,
  .btn-date,
  .strategy-lots,
  .strategy-strike {
    height: 24px;
  }

  .expiry-dropdown-screener {
    width: 81px !important;
  }
}

@media (max-width: 400px) {
  .custom-success-toast {
    right: 29px;
  }

  .search-modal .modal-dialog {
    margin-left: 26px;
  }
}

@media (max-width: 389px) {
  .confirm-popup-toast {
    left: 11px;
  }

  .search-modal .modal-dialog {
    margin-left: 20px;
  }

}

@media (max-width: 391px) {
  .custom-success-toast {
    right: 21px;
  }

}

@media (max-width: 361px) {
  .confirm-popup-toast {
    left: 4px;
  }

  .custom-success-toast {
    right: 12px;
  }
}


.parallax-table {
  display: flex;
  overflow: hidden;
  position: relative;
}

.left-side,
.center-column,
.right-side {
  width: 100%;
  height: 100%;
  display: inline-block;
}



table.table.table-responsive.left-side tr>td:nth-last-child(2) {
  position: sticky;
  right: 56px;
}

table.table.table-responsive.left-side tr>th:nth-last-child(2) {
  position: sticky;
  right: 56px;
}

table.table.table-responsive.left-side tr>td:last-child {
  position: sticky;
  right: 0;
}

table.table.table-responsive.left-side tr>th:last-child {
  position: sticky;
  right: 0;
}