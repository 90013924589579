@import url("./css/satoshi.css");

body {
  margin: 0;
  font-family: "Satoshi-Variable", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Satoshi-Variable", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

/* Common styles for screens larger than 420px */
.grid-container {
  display: grid;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  /* grid-template-columns: repeat(16, 1fr); */
  max-width: 1392px;
  margin: 30px auto 0 auto;
}

/* Styles for screens up to 420px (mobile) */

@media (max-width: 420px) {
  .grid-container {
    display: grid;
    padding: 0 12px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    max-width: 420px;
    margin: 0;
    /* border: 1px solid red; */
    margin: 77px auto 0 auto
  }
}